@import "../tiles";

rh-search-tile {
  rh-tile-card-header {
    height: @SmallTileHeaderHeight;
    background-color: @DefaultHeaderColor;
  }

  .tile-title {
    .default-tile-title;
  }

  .vcenter {
    display: table-cell;
    vertical-align: middle;
  }

  rh-tile-card-header {
    height: @LargePictureHeight;

    .picture {
      .spanning-picture;
    }
  }

  rh-tile-card-body {
    .tile-content {
      overflow: hidden;
    }
  }

  .fade-out-line {
    bottom: 70px !important;
  }

  .search-box {
    width: 100%;

    input {
      width: 100%;
      padding-right: 15px;
    }
  }
}
