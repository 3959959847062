@import "../../../GlobalStyles/globalMixins.less";
@import "../../../GlobalStyles/globalVariables.less";

rh-side-pane {
  @sideBarTransition: margin 200ms ease-out;
  @hoverWidth: 200px;
  @unpinnedMarginOffset: @hoverWidth - 337px;
  @sidePaneHeaderHeight: 47px;
  @outerBorderWidth: 1px;

  .sidebar {
    position: fixed;
    z-index: 1035;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: @hoverWidth;
    margin-top: @navBarHeight;
    margin-bottom: 0;
    height: ~"calc(100% - @{navBarHeight})"; //ensures correct scroll area for nested content

    .transition(@sideBarTransition);

    .container-fluid {
      padding: 0;
      height: 100%;
    }

    header {
      color: #fff;
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
      height: @sidePaneHeaderHeight;
      overflow: hidden;
      border-bottom: 1px #e5e5e5 solid;
      padding-top: 13px;
      padding-bottom: 0px;
      padding-left: 11px;
      padding-right: 21px;
      background-color: white;
      z-index: 1;

      .pin-button,
      .btn-link {
        padding: 0;
        width: 26px;
        height: 26px;
      }
    }

    .btn-default {
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 1px;
      padding-bottom: 1px;
    }

    .btn-link {
      color: #707070;

      &:hover,
      &:focus {
        color: black;
      }
    }

    &.hidden {
      display: none;
    }

    &.expanded,
    &.pinned {
      width: 25vw;
      max-width: 25vw;
      min-width: @hoverWidth;

      header {
        position: fixed;
        width: ~"calc(25vw - @{outerBorderWidth})"; //-1px to not hide outer border
        min-width: @hoverWidth - @outerBorderWidth; //-1px to not hide outer border
        max-width: 25vw;
      }

      .sidepane-content-wrapper {
        //The wrapper ensures scroll bar of actual content area starts below header
        padding-top: @sidePaneHeaderHeight;
      }
    }

    .sidepane-content-wrapper {
      padding-top: 0;
      overflow: hidden;
      height: inherit;

      .sidepane-content {
        overflow: auto;
        height: inherit;
      }
    }
    //Panel body border variables
    @bodyBorderStyle: 4px solid #e4e9ee;

    &.pull-left {
      margin-left: @unpinnedMarginOffset;
      left: 0;
      border-right: solid @outerBorderWidth #ccc;

      header {
        padding-left: 11px;
        padding-right: 21px;
      }

      &.expanded,
      &.pinned {
        margin-left: 0;
      }

      .panel-body {
        border-left: @bodyBorderStyle;
      }
    }

    &.pull-right {
      margin-right: @unpinnedMarginOffset;
      right: 0;
      border-left: solid @outerBorderWidth #ccc;

      header {
        padding-right: 11px;
        padding-left: 21px;
      }

      &.expanded,
      &.pinned {
        margin-right: 0;
      }

      .panel-body {
        border-right: @bodyBorderStyle;
      }
    }
  }
}
