rh-status-search-filter {
  md-checkbox {
    display: block;

    .md-icon {
      transform: scale(0.7);
    }

    &.md-checked .md-icon {
      background-color: #337ab7; /*TODO change global styling of md, or remove md*/
    }

    .md-label {
      padding-left: 3px;
    }
  }
}
