@import "../../../GlobalStyles/globalVariables.less";

rh-search-result-abstract {
    .abstract-container {
        margin-bottom: 15px;
        margin-top: 10px;
        background-color: #f2f2f2;
        line-height: 1.5em;
        height: 3em;
        padding-left: 5px;
        padding-right: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
