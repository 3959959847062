/* ---------------------------- Members ------------------------------- */

#asset-collection-members-list md-card {
  width: 300px;
  float: left;
}

#asset-collection-members-list .md-headline {
  word-break: break-word;
}

#asset-collection-members-list md-card span.permission {
  display: inline-block;
  margin-right: 5px;
}

/* ---------------------------- Releases ------------------------------- */

.assets-collection-release-artifacts a.btn {
  margin-right: 5px;
}

.assets-collection-release-artifacts a.btn i {
  padding-left: 5px;
}

.assets-collection-release-artifacts .md-spinner-wrapper {
  margin-right: 5px;
  padding-left: 5px;
}

.assets-collection-release-artifacts .icons {
  width: 40px;
  display: inline-block;
}

/* ---------------------------- Tree ------------------------------- */

.asset-collection-tree-view .angular-ui-tree-handle {
  border: 1px solid #dae2ea;
  color: #7c9eb2;
  padding: 8px 8px;
  cursor: pointer;
  outline: none;
}

.asset-collection-tree-view .angular-ui-tree-handle:hover {
  background: #f4f6f7;
  outline: none;
}

.asset-collection-tree-view .angular-ui-tree-handle:focus {
  outline-width: 1px;
  outline-style: solid;
}

.asset-collection-tree-view .angular-ui-tree-handle.active-node:focus {
  outline: none;
}

.asset-collection-tree-view .angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.asset-collection-tree-view tr.angular-ui-tree-empty {
  height: 100px;
}

.asset-collection-tree-view .group-title {
  background-color: #687074 !important;
  color: #fff !important;
}

.asset-collection-tree-view .tree-node {
  border: 1px solid #dae2ea;
  background: #f8faff;
  color: #7c9eb2;
}

.asset-collection-tree-view .nodrop {
  background-color: #f2dede;
}

.asset-collection-tree-view .tree-node-content {
  margin: 10px;
}

.asset-collection-tree-view .tree-handle {
  padding: 10px;
  background: #428bca;
  color: #fff;
  margin-right: 10px;
}

.asset-collection-tree-view .angular-ui-tree-node {
  margin-top: 10px;
}

.asset-collection-tree-view .node-icon {
  vertical-align: top;
  padding-right: 10px;
  font-size: 28px;
}

.asset-collection-tree-view .angular-ui-tree-handle {
  cursor: default;
}

.asset-collection-tree-view .rhs-tree-item {
}

.asset-collection-tree-view .node-info {
  margin-top: 5px;
}

.asset-collection-tree-view .node-name {
  font-size: 16px;
  display: block;
  color: #656565;
}

.asset-collection-tree-view .node-quote {
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.asset-collection-tree-view .node-name a:focus,
.asset-collection-tree-view .node-name a:hover {
  text-decoration: underline;
}

.asset-collection-tree-view .node-meta {
  font-size: 12px;
  display: inline-block;
}

.asset-collection-tree-view .rhs-tree-item {
  padding: 5px;
}

.asset-collection-tree-view .rhs-tree-item .btn {
  padding: 8px;
  border-radius: 50%;
}

.logical-node {
  color: #7c9eb2;
}

.dimmed {
  opacity: 0.5;
}

.asset-collection-tree-view .move-asset-btn {
  margin: 5px 0;
}

.asset-collection-tree-view .move-asset-btn .btn {
  border-style: dashed;
  border-width: 2px;
  width: 100%;
  font-size: 10px;
}

.asset-collection-tree-view .move-asset-btn .btn-default {
  border-color: #666666;
}

.child-node-target {
  margin-left: 20px;
}

.tree-view-actions-container {
  position: relative;
  float: left;
  min-height: 472px;
}

.accelerator-key {
  display: inline;
  text-decoration: underline;
}

.tree-view-actions {
  margin-top: 15px;
}

.tree-view-actions.affix {
  top: 100px;
}

.tree-view-actions.affix-top {
}

.tree-view-actions.affix-bottom {
}

.tree-view-actions ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.tree-view-actions ul li {
  margin-bottom: 15px;
  text-align: center;
}

.tree-view-actions ul li a {
  border: solid 1px;
  padding: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}

.tree-view-actions ul li span.action-caption {
  font-size: 11px;
  display: block;
  text-align: center;
  margin-top: 3px;
}

.tree-view-actions ul li.disabled a,
.tree-view-actions ul li.disabled md-icon,
.tree-view-actions ul li.disabled > span {
  color: #ccc;
}

.asset-collection-tree-view .active-node {
  background: #f4f6f7;
  outline: none;
  border: solid;
}

.asset-collection-tree-view .angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
