@import "../../../GlobalStyles/globalVariables.less";

rh-product-page-taxonomy {
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  a.taxonomy-search-link {
    margin: 5px 0 0 5px;
    padding: 0;
    display: inline-block;
    color: @linkColor;
  }

  .taxonomy-feature {
    ul.document-list {
      padding-left: 15px;
    }

    /*display: block;*/
    ul.nav {
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;

      ul.nav {
        margin-left: 0;
        border-left: 1px #ccc solid;
        margin-left: 5px;
      }

      li {
        @actionButtonsPlaceholderWidth: 85px;
        @minimumTaxonLabelWidth: 50px;
        white-space: nowrap;
        margin-top: 3px;

        * {
          vertical-align: top;
        }

        a {
          display: inline-block;

          &.item-text-link {
            color: @linkActionColor;
            cursor: pointer;
            text-decoration: none;
            padding-left: 20px;
            padding-right: 0px;
            padding-top: 2px;
            padding-bottom: 2px;
            max-width: ~"calc(100% - @{actionButtonsPlaceholderWidth})";
            min-width: @minimumTaxonLabelWidth;
            //the next section is to fix overflow ellipsis with before pseudo element!
            overflow: hidden;
            text-overflow: ellipsis;

            &:before {
              display: inline-block;
              position: absolute; //important to not kill ellipsis on a tag
              left: 0px;
              text-decoration: none;
              font-size: 1em;
              font-family: arial;
              padding-right: 4px;
            }

            &:after {
              display: none;
            }

            &.as-folder {
              &.collapsed:before {
                font-family: "FontAwesome";
                content: "\f07b";
              }
              &:not(.collapsed):before {
                font-family: "FontAwesome";
                content: "\f07c";
              }
            }

            &:not(.as-folder) {
              &.collapsed:before {
                content: "+";
              }
              &:not(.collapsed):before {
                content: "−";
              }
            }

            &.item-text-link-empty:before {
              color: @lightGreyColor;
            }
          }

          &.document-link {
            cursor: pointer;
            text-decoration: none;
            padding-left: 25px;
            padding-right: 0px;
            padding-top: 2px;
            padding-bottom: 2px;
            max-width: ~"calc(100% - 10px)";
            min-width: @minimumTaxonLabelWidth;
            //the next section is to fix overflow ellipsis with before pseudo element!
            overflow: hidden;
            text-overflow: ellipsis;

            &:before {
              display: inline-block;
              position: absolute; //important to not kill ellipsis on a tag
              left: 5px;
              text-decoration: none;
              font-size: 1em;
              font-family: arial;
              padding-right: 4px;
              font-family: "FontAwesome";
              content: "\f016";
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: @smallMobile) {
    .taxonomy-feature {
      ul.nav {
        ul.nav {
          border-left: none;
        }

        li {
          margin: 0;

          .taxonomy-row {
            border: solid rgb(0, 50, 99) 1px;
            border-right: none;
            padding-left: 40px;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-top: -1px;

            &.header {
              background-color: @lightGreyColor;
            }
          }

          .sub-tree {
            background-color: white;

            &:last-child {
              border-bottom: none;
            }
          }

          a {
            &.item-text-link {
              &:hover,
              &:focus {
                outline: none;
                background-color: @lightGreyColor;
              }

              &.as-node:before,
              &.as-folder:before {
                margin-left: 10px;
              }

              &.item-text-link-expanded:before,
              &.item-text-link-expanded:before {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
