@import "../tiles";

rh-document-list-tile {
  rh-tile-card-header {
    height: @SmallTileHeaderHeight;
    background-color: @DefaultHeaderColor;
  }

  rh-tile-card-body {
    .tile-content {
      overflow: auto;
    }
  }

  .tile-title {
    margin-top: 26px;
    display: inline-block;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px;
    font-size: @FontSize;

    &:first-letter {
      text-transform: capitalize;
    }

    font-weight: 100;
    z-index: 999;
    max-width: 80%;
    position: absolute;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      overflow: hidden;
      margin-bottom: 8px;

      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
