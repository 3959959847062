@import "../tiles";

rh-tile-card .card {
  height: @TileHeight;
  margin: 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;

  /* 
       This btn style should be analyzed probably be moved some global style.        
       Probably it should be removed entirely
    */
  .btn {
    border-radius: 0;
  }

  .spinner-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-scrollbar {
    overflow: hidden;
  }

  .scrollbar {
    overflow: auto;
  }

  rh-tile-card-header {
    overflow: hidden;
    position: relative;
  }

  rh-tile-card-body {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 10px 20px 20px 20px;
    position: relative;

    .tool-tip {
      position: absolute;
      left: 0;
      width: auto;
      top: 0px;
      z-index: 2000;
      padding: 5px;
      border-radius: 6px;
      margin: 5px;
      background-color: #eee;
    }

    .fade-out-line {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 56px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100,1+100 */
      background: -moz-linear-gradient(
        bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0) 30px
      );
      /* FF3.6-15 */
      background: -webkit-linear-gradient(
        bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 30px
      );
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 30px
      );
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      pointer-events: none;
    }

    > div[ng-transclude] {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      display: flex;
      flex-flow: column;

      h2,
      h3 {
        font-size: 21px;
        margin-top: 0;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      ng-transclude {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        display: flex;
        flex-flow: column;
        overflow: hidden;

        > .tile-content {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 0;
        }

        > .tile-action {
          > a {
            height: 36px;
          }
        }
      }
    }

    /* Subset of https://cdn.quilljs.com/1.3.6/quill.core.css
      to fix an issue with nested lists..

      See issue https://github.com/quilljs/quill/issues/979
      */
    .ql-viewer {
      ol,
      ul {
        margin: 0;
        padding: 0;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
          list-9;
      }

      ol,
      ul {
        padding-left: 1.5em;
      }

      ol > li,
      ul > li {
        list-style-type: none;
      }

      ul > li::before {
        content: "\2022";
      }

      li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }

      li::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }

      ol li,
      ul li {
        padding-left: 1.5em;
      }

      ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
          list-9;
        counter-increment: list-0;
      }

      ol li:before {
        content: counter(list-0, decimal) ". ";
      }

      ol li.ql-indent-1 {
        counter-increment: list-1;
      }

      ol li.ql-indent-1:before {
        content: counter(list-1, lower-alpha) ". ";
      }

      ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      }

      ol li.ql-indent-2 {
        counter-increment: list-2;
      }

      ol li.ql-indent-2:before {
        content: counter(list-2, lower-roman) ". ";
      }

      ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      }

      ol li.ql-indent-3 {
        counter-increment: list-3;
      }

      ol li.ql-indent-3:before {
        content: counter(list-3, decimal) ". ";
      }

      ol li.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
      }

      ol li.ql-indent-4 {
        counter-increment: list-4;
      }

      ol li.ql-indent-4:before {
        content: counter(list-4, lower-alpha) ". ";
      }

      ol li.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
      }

      ol li.ql-indent-5 {
        counter-increment: list-5;
      }

      ol li.ql-indent-5:before {
        content: counter(list-5, lower-roman) ". ";
      }

      ol li.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
      }

      ol li.ql-indent-6 {
        counter-increment: list-6;
      }

      ol li.ql-indent-6:before {
        content: counter(list-6, decimal) ". ";
      }

      ol li.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
      }

      ol li.ql-indent-7 {
        counter-increment: list-7;
      }

      ol li.ql-indent-7:before {
        content: counter(list-7, lower-alpha) ". ";
      }

      ol li.ql-indent-7 {
        counter-reset: list-8 list-9;
      }

      ol li.ql-indent-8 {
        counter-increment: list-8;
      }

      ol li.ql-indent-8:before {
        content: counter(list-8, lower-roman) ". ";
      }

      ol li.ql-indent-8 {
        counter-reset: list-9;
      }

      ol li.ql-indent-9 {
        counter-increment: list-9;
      }

      ol li.ql-indent-9:before {
        content: counter(list-9, decimal) ". ";
      }

      .ql-indent-1 {
        padding-left: 3em;
      }

      li.ql-indent-1 {
        padding-left: 4.5em;
      }

      .ql-indent-2 {
        padding-left: 6em;
      }

      li.ql-indent-2 {
        padding-left: 7.5em;
      }

      .ql-indent-3 {
        padding-left: 9em;
      }

      li.ql-indent-3 {
        padding-left: 10.5em;
      }

      .ql-indent-4 {
        padding-left: 12em;
      }

      li.ql-indent-4 {
        padding-left: 13.5em;
      }

      .ql-indent-5 {
        padding-left: 15em;
      }

      li.ql-indent-5 {
        padding-left: 16.5em;
      }

      .ql-indent-6 {
        padding-left: 18em;
      }

      li.ql-indent-6 {
        padding-left: 19.5em;
      }

      .ql-indent-7 {
        padding-left: 21em;
      }

      li.ql-indent-7 {
        padding-left: 22.5em;
      }

      .ql-indent-8 {
        padding-left: 24em;
      }

      li.ql-indent-8 {
        padding-left: 25.5em;
      }

      .ql-indent-9 {
        padding-left: 27em;
      }

      li.ql-indent-9 {
        padding-left: 28.5em;
      }
    }
  }
}
