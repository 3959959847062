@import "../tiles";
rh-document-tile {
  .spinner-container {
    .center-container;
  }
  rh-spinner {
    .center;
  }
  .tile-title {
    .default-tile-title;
  }
  .tile-picture {
    overflow: hidden;
    position: relative;
  }
  .tile-picture-height {
    height: @LargePictureHeight;
  }
  .tile-picture-alt-height {
    height: 70px;
  }
  .tile-picture .picture {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .tile-content {
    overflow: hidden;
    /*.tile-content-height added due to problems overflow: hidden; with height:100%*/
    margin: 0px 20px;
    padding: 0px 0px 2px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: auto;
    -moz-column-width: auto;
    column-width: auto;
  }
  .tile-content-height {
    height: 150px;
  }
  .tile-content-alt-height {
    height: 280px;
  }
  .tile-content .document-title {
    margin: 16px 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tile-content .document-abstract h1 {
    font-size: 18px;
    margin-top: 16px;
  }
  .tile-content .document-abstract h2 {
    font-size: 16px;
    margin-top: 16px;
  }
  .tile-content .document-abstract h3,
  h4,
  h5 {
    font-size: 14px;
    margin-top: 16px;
  }
}
