rh-refinements-search-filter {
  md-checkbox {
    display: block;

    .md-icon {
      transform: scale(0.7);
    }

    &.md-checked .md-icon {
      background-color: #337ab7;
    }

    .md-label {
      padding-left: 3px;
    }

    .badge {
      margin-left: 8px;
    }
  }
}
