@import "../tiles";

rh-link-tile {
  rh-tile-card-header {
    height: @SmallTileHeaderHeight;
    background-color: @DefaultHeaderColor;
  }

  .tile-title {
    .default-tile-title;
  }

  .vcenter {
    display: table-cell;
    vertical-align: middle;
  }

  rh-tile-card-header {
    height: @LargePictureHeight;

    .picture {
      .spanning-picture;
    }
  }
}
