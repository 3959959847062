rh-saved-searches {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    > li {
      padding: 0px 10px 20px;
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  }

  .actions {
    display: flex;
    margin-top: -4px;
    > .item {
      margin-left: 5px;
      border-radius: 20px;
      background-color: #f1f1f1;
      padding: 5px 10px;
      color: #4d4d4d;
      cursor: pointer;

      &:hover {
        background-color: #d1d1d1;
        color: #000;
      }
    }
  }
}
