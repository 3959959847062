rh-product-page-view {
  > ng-container > div {
    padding-top: 23px;
  }
  .product-title {
    position: fixed;
    width: 100%;
    margin-top: -23px;
    z-index: 1000;
    h1 {
      color: #000;
      background-color: #dddddd;
      padding: 4px 20px 4px;
      font-size: 14px;
      text-transform: none;
      font-weight: 100;
      margin: 0;
    }
  }
  rh-tiles-layout .container-fluid {
    padding-top: 19px;
  }
}
