@import "../GlobalStyles/globalVariables.less";

body {
  // Hack to ensure that scrollbars don't overlap in Internet Explorer
  -ms-overflow-style: scrollbar !important;
  font-family: @fontPrimary;
}

a {
  color: @linkColor;
  text-decoration: none;
}

md-checkbox {
  // WCAG 2.1, interactable elements should have a hover-effect
  &:hover {
    .md-icon {
      background-color: rgba(127, 127, 127, 0.2) !important;
    }
  }

  .md-icon {
    background-color: white !important;
    border-color: @checkBoxColor !important;
    transform: scale(0.7);

    &:after {
      display: block !important;
      border-color: @checkBoxColor !important;
    }
  }
}

.btn {
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-danger {
  background-color: @dangerBackgroundColor;
  border-color: @dangerBorderColor;
}

.form-control,
.input-group-addon {
  border-radius: @fieldBorderRadius;
}

[translate-cloak] {
  &.translate-cloak {
    // "display: none" would we better instead of "opacity: 0" to reduce rendering-overhead until DOM is ready, but ie11 has problems using autofocus attribute together with display: none
    opacity: 0;
  }
}
