html {
  font-size: 100%;
  font-size: unset;
  background: #f1f1f1;
}
body {
  background: #f1f1f1;
}

.no-margin {
  margin: 0;
}

.m {
  margin: 15px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-v-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-v-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-v {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-v-md {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-v-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-h-xs {
  margin-right: 5px;
  margin-left: 5px;
}

.m-h-sm {
  margin-right: 10px;
  margin-left: 10px;
}

.m-h {
  margin-right: 15px;
  margin-left: 15px;
}

.m-h-md {
  margin-right: 20px;
  margin-left: 20px;
}

.m-h-lg {
  margin-right: 30px;
  margin-left: 30px;
}

.m-t {
  margin-top: 15px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-r {
  margin-right: 15px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-l {
  margin-left: 15px;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-n {
  margin: -15px;
}

.m-h-n {
  margin-right: -15px;
  margin-left: -15px;
}

.m-v-n {
  margin-top: -15px;
  margin-bottom: -15px;
}

.m-l-n {
  margin-left: -15px;
}

.m-r-n {
  margin-right: -15px;
}

.m-t-n {
  margin-top: -15px;
}

.m-b-n {
  margin-bottom: -15px;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-h {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-xs {
  padding: 5px;
}

.p-sm {
  padding: 10px;
}

.p {
  padding: 15px;
}

.p-md {
  padding: 20px;
}

.p-lg {
  padding: 30px;
}

.p-h {
  padding-right: 15px;
  padding-left: 15px;
}

.p-h-xs {
  padding-right: 5px;
  padding-left: 5px;
}

.p-h-sm {
  padding-right: 10px;
  padding-left: 10px;
}

.p-h-md {
  padding-right: 20px;
  padding-left: 20px;
}

.p-h-lg {
  padding-right: 30px;
  padding-left: 30px;
}

.p-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-v-lg {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .p-md,
  .p-lg {
    padding: 15px;
  }

  .p-h-md,
  .p-h-lg {
    padding-right: 15px;
    padding-left: 15px;
  }

  .p-v-md,
  .p-v-lg {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.no-radius {
  border-radius: 0;
}

.no-r-t {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.no-r-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.no-r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.no-r-l {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.r {
  border-radius: 0;
}

.r-t {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.r-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.r-l {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.r-2x {
  border-radius: 0;
}

.r-3x {
  border-radius: 0;
}

.full-width {
  width: 100%;
}

.error-page-container {
  margin-top: 80px;
}

.md-select-menu-container {
  z-index: 1050;
}

md-backdrop.md-select-backdrop {
  z-index: 1041;
}
