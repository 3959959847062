@import "../../GlobalStyles/globalVariables.less";

rh-search-view {
  .query-panel {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    .smallDeviceTablet({flex-wrap: wrap;});

    rh-search-assistent {
      width: 100%;
      .smallMobile({flex: 1;});
      .extraSmallMobile({flex: 1;});
    }

    .reset-button {
      border-width: 2px;
      min-width: 75px;
      min-height: 52px;
      .smallMobile({flex: 1 1 auto; border-width: 1px;});
      .smallDeviceTablet({ min-height: 40px;});
    }

    .result-sorter-spacer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 160px;
      margin-left: 12px;
      .smallDeviceTablet({margin-top: 12px;});
    }

    .reset-button-spacer {
      flex: 1;
      min-width: 75px;
      margin-left: 12px;
      .smallDeviceTablet({margin-top: 12px;});
    }
  }
  .center-view.container-fluid {
    padding: 15px;
    .smallMobile({padding-top: 5px;});
  }
}
