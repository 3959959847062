@import "../../../GlobalStyles/globalVariables.less";
rh-criteria-panel {
  .search-chips {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    .smallMobile(
      {display: flex; flex-wrap: wrap; padding-bottom: 4px; margin-bottom: 6px;}
    );

    .filter {
      @trashButtonWidth: 41px;
      border: 2px solid transparent;
      position: relative;
      background-color: #fff;
      display: inline-block;
      padding: 4px (@trashButtonWidth+14) 4px 15px;
      height: 50px;
      margin-right: 20px;
      margin-bottom: 10px;
      max-width: 600px;
      .smallMobile({max-width: 345px; margin: 2px; flex-grow: 1;});
      .extraSmallMobile({max-width: 290px;});
      .info {
        width: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: flex;
        line-height: 40px;
        margin: 0px 0px 0px 0px;
        padding-top: 12px;
        height: 100%;
        .info-icon {
          margin-right: 10px;
        }
        .criteria-panel-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          overflow: hidden !important;
          line-height: 15px;
          min-width: 50px;
          .smallMobile({min-width: 0;});
        }
      }
      .trash {
        background-color: @btnColorMedium;
        color: #fff;
        margin-left: 10px;
        width: @trashButtonWidth;
        height: 42px;
        padding-top: 10px;
        position: absolute;
        top: 2px;
        bottom: 0px;
        right: 2px;
        &:hover,
        &:active,
        &:focus {
          background-color: darken(@btnColorMedium, 10%);
        }
      }
      /* animate chip enter/remove */
      &.ng-enter,
      &.ng-leave {
        @transitionSpec: 100ms linear all;
        -webkit-transition: @transitionSpec;
        -moz-transition: @transitionSpec;
        -ms-transition: @transitionSpec;
        -o-transition: @transitionSpec;
        transition: @transitionSpec;
        .trash {
          display: none;
        }
      }
      &.ng-enter,
      &.ng-leave.ng-leave-active {
        opacity: 0;
        width: 0px;
        .smallMobile(
            {//on mobile the chips are more stacked, so we animate height instead of width

              width: inherit; height: 0px;}
          )
          ;;
      }
      &.ng-leave,
      &.ng-enter.ng-enter-active {
        opacity: 1;
      }
    }
  }
}
