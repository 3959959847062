@import "../../../GlobalStyles/globalVariables.less";

rh-navbar {
  nav.navbar {
    min-height: 50px;
    margin-bottom: 20px;
    height: @navBarHeight;
    z-index: 1045;

    .document-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: @navBarHeight;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #9d9d9d;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .nav {
      &.navbar-nav {
        a {
          padding: 22px;
          font-size: 14px;
          font-weight: 500;
          height: @navBarHeight;

          .caret {
            margin-left: 7px;
            margin-top: 9px;
            position: absolute;
          }

          .fa {
            font-size: 19px;
          }
        }

        .dropdown-menu {
          max-height: ~"calc(100vh - 100%)";
          overflow-y: auto;
          &.product-list {
            i.fa {
              font-size: 14px;
            }
          }
          a {
            font-weight: normal;
            text-transform: none;
            padding: 15px 22px;
            height: auto;
            text-align: right;
          }

          > .disabled > a,
          > .disabled > a:hover,
          > .disabled > a:focus {
            color: #777;
          }
        }

        .navbar-right .dropdown-menu {
          right: 0;
          left: auto;
        }
      }
    }

    &.navbar-inverse .navbar-toggle {
      border-color: #333;
      border-radius: 25px;
      width: 50px;
      height: 50px;
      font-size: 21px;
      background: #fff;
      color: #346ab3;

      &:hover,
      &:active,
      &:focus {
        color: #fff;
        background-color: #333;
      }
    }
  }

  @media (min-width: @smallDeviceTablet) {
    nav.navbar .nav.navbar-nav a .nav-item-title {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }
  }

  @media (max-width: @smallDeviceTablet) {
    nav {
      &.navbar {
        opacity: 1;

        .nav.navbar-nav {
          a {
            .nav-item-title {
              margin-left: 10px;
            }

            &.dropdown-toggle .nav-item-title {
              margin-left: 25px;
            }
          }

          .navbar-collapse {
            width: 100vw;
            padding: 0;
            position: absolute;
            max-height: none;

            a {
              padding: 5px 22px;
              font-size: 14px;
              font-weight: 500;
            }
          }

          .dropdown-menu {
            max-height: unset;
            padding-top: 0;
            margin-left: 45px;

            li a {
              text-align: left;
            }
          }
        }

        .navbar-collapse.collapse,
        .navbar-collapse.collapsing {
          border: none;
          background-color: #fff;
        }
      }
    }
  }

  @media (max-width: @smallMobile) {
    nav {
      &.navbar {
        .navbar-header a.navbar-brand {
          display: none;
        }

        #nav-menu {
          max-height: 400px;
          overflow-y: scroll;
        }

        .navbar-collapse.collapse,
        .navbar-collapse.collapsing {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

// We use navbar-inverse and style it as navbar-default with tons of custom CSS?
// TODO: Remove this CSS and change navbar-inverse to navbar-default in navbar.html
//    Remember to create changescript for ALL customers using custom style targeting .navbar-inverse...

.navbar {
  background-color: #fff;
  color: @linkColor;
  border: none;
  border-bottom: solid 1px #ccc;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: @lightGreyColor;
  color: @linkColor;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  background-color: @lightGreyColor;
  color: @linkColor;
}

nav.navbar .nav.navbar-nav .dropdown-menu a {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: @linkColor;
}

nav.navbar .nav.navbar-nav .dropdown-menu .product-list-divider {
  margin: 5px 0;
  border-top: 1px solid rgba(50, 50, 50, 0.3);
}

nav.navbar .nav.navbar-nav .dropdown-menu a:hover,
nav.navbar .nav.navbar-nav .dropdown-menu a:focus {
  background-color: @lightGreyColor;
  color: @linkColor;
}
