@import "../../../GlobalStyles/globalMixins.less";

rh-side-pane-section {
  position: relative;
  display: block;
  width: 100%;

  .panel {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;

    .panel-heading {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: none;
      min-height: 65px;
      background-color: transparent !important;
      padding: 15px 13px;
      min-width: 200px;
      overflow: hidden;
      border-color: #ddd;

      .panel-title {
        line-height: 34px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        color: inherit;

        .pull-left {
          padding-right: 12px;
        }

        i.fa {
          margin-left: 0px;
          width: 40px;
          text-align: center;
          font-size: 26px;
          color: #707070;
          margin-top: 5px;
        }
        .small-font {
          font-size: 14px !important;
        }
      }
    }

    .panel-collapse {
      .panel-body {
        margin-bottom: 0;
        border-radius: 0;
        padding: 5px 5px 5px 10px;

        .btn {
          padding: 10px 12px 11px;
          border-radius: 0;
          border: 0;

          &.btn-primary,
          &.label-primary {
            color: #fff;
            background-color: #133f7f;
            border-color: #133f7f;
          }

          + .btn {
            border-top: 1px solid #fff;
          }
        }

        &.panel-auto-padding-disabled {
          padding: 0;
        }
      }
    }
  }

  .modal-close-button {
    font-size: 2em;

    &:hover {
      color: #f00;
    }
  }
}
