table.assets-collection-list {
  td,
  th {
    text-align: left;
    vertical-align: middle !important;
    &.document-type-icon {
      text-align: center;
    }
  }
}
