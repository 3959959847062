rh-multi-select-product-search-filter {
  md-input-container {
    max-width: 100%;
    min-width: 62%;
  }
}

md-content:has(.product-option) {
  scroll-snap-type: y mandatory;

  .product-option {
    scroll-snap-align: start;
    &:nth-last-of-type(.owned) {
      border-bottom: 1px solid rgba(50, 50, 50, 0.3);
    }

    .md-text {
      width: 100%;
    }

    i {
      color: #707070;
      font-size: 20px;
      align-items: center;
      font-size: inherit;
      // width: 48px;
      display: flex;
      justify-content: center;
    }
    .md-text {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
  .product-option-content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .product-list-divider {
    margin: 5px 0;
    border-top: 1px solid rgba(50, 50, 50, 0.3);
  }
}
