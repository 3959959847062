@import "../../../GlobalStyles/globalVariables.less";

rh-search-result-mobile {
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .search-container {
    min-height: 200px;
    margin-top: 5px;

    .smallMobile({position: relative;});

    .searching {
      .extraSmallMobile({margin-top: 60px;});
    }

    .search-item {
      background: #fff;
      overflow: hidden;
      padding: 0 20px 20px;
      margin-bottom: 0;
      border-bottom: 1px solid #848484;
      .smallMobile({padding: 0 10px 10px;});
      .extraSmallMobile({padding: 0 10px 10px;});
      .result-titel {
        font-size: 18px;
        .smallMobile({font-size: 1em;});
        .extraSmallMobile({font-size: 1em;});
      }

      &:last-child {
        border-bottom: none;
      }

      em {
        background-color: yellow;
      }
    }
  }
}
