@import "../../../GlobalStyles/globalVariables.less";
rh-search-result {
  .search-container {
    min-height: 200px;
    margin-top: 5px;
    .smallMobile({position: relative;});
    .searching {
      .extraSmallMobile({margin-top: 60px;});
    }
    .search-item {
      background: #fff;
      overflow: hidden;
      padding: 0 20px 20px;
      margin-bottom: 0;
      border-bottom: 1px solid #848484;
      .smallMobile({padding: 0 10px 10px;});
      .extraSmallMobile({padding: 0 10px 10px;});
      .result-titel {
        font-size: 18px;
        .smallMobile({font-size: 1em;});
        .extraSmallMobile({font-size: 1em;});
      }
      em {
        background-color: #ffff22d1;
        font-style: normal;
      }
      .search-item-header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        gap: 10px;
        .result-titel {
          flex-grow: 1;
          margin: 0;
          .md-primary {
            min-width: 30px;
          }
        }
        .show-more-btn {
          min-width: 110px;
          text-align: right;
          align-self: flex-start;
        }
      }

      &:last-child {
        border-bottom: none;
      }
      .search-item-sliceContainer {
        margin-bottom: 1em;
        .search-item-slice {
          border-bottom: 1px solid lightgrey;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          margin-left: 1em;
          .search-item-slice-link {
            text-decoration: none;
            cursor: pointer;
            display: block;
            margin-bottom: 15px;
          }
        }
      }
      .snippets {
        list-style: none;
        padding-left: 20px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  .result-count {
    padding-right: 20px;
    color: #848484;
    width: 100%;
    border-bottom: 1px solid #848484;
    text-align: right;
  }

  h1 md-checkbox {
    margin-bottom: 0;
  }

  a:visited {
    color: #7ea7d9;
  }
}
