/* Variables */

@TileHeightValue: 350;
@TileHeight: ~"@{TileHeightValue}px";
@FontSize: 14px;
@LineHeight: 1.428571429;
@LargePictureHeight: 200px;
@SmallTileHeaderHeight: 80px;
@DefaultHeaderColor: rgba(128, 128, 128, 0.18);
/* mixins */

.default-tile-title() {
  margin-top: 26px;
  display: inline-block;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px;
  font-size: @FontSize;
  text-transform: uppercase;
  font-weight: 100;
  z-index: 999;
  max-width: 80%;
  position: absolute;
}

.center() {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.center-container() {
  display: table;
  width: 100%;
  height: 100%;
}

.suppress-text-cutoff() {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-width: auto;
  -moz-column-width: auto;
  column-width: auto;
}

.cut-off-text(@LinesToShow) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spanning-picture {
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
  }
}
