@import "../../../GlobalStyles/globalVariables.less";
rh-main-content .main {
  -webkit-transition: padding 200ms ease-out;
  -moz-transition: padding 200ms ease-out;
  -o-transition: padding 200ms ease-out;
  transition: padding 200ms ease-out;
  position: absolute;
  width: 100vw;
  float: right;
  overflow-x: auto;
  overflow-y: scroll;
  .smallDeviceTablet({height: 90vh;});
  .smallMobile({height: 90vh;}); // Should be calculated properly
  .panel-body {
    padding-left: 30px; // Increase padding on document to allow room for note icons
    padding-right: 30px;
  }
  .extraSmallMobile({height: 90vh;}); // Should be calculated properly
  &.left-side-pane-show {
    padding-left: 60px;
  }
  &.right-side-pane-show {
    padding-right: 60px;
    rh-document-status-watermark .document-status {
      margin-right: 60px;
    }
  }
  &.left-side-pane-pinned.left-side-pane-expanded {
    padding-left: 25vw;
  }
  &.right-side-pane-pinned.right-side-pane-expanded {
    padding-right: 25vw;
    rh-document-status-watermark .document-status {
      margin-right: 25vw;
    }
  }
  /* Bootstrap .xs */
  @media (max-width: @smallMobile) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    rh-document-status-watermark .document-status {
      margin-right: 0 !important;
    }
  }
}
