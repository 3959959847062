@import "../tiles";
rh-login-tile {
  rh-tile-card-header {
    height: @LargePictureHeight;

    .picture {
      .spanning-picture;
    }
  }

  .tile-title {
    .default-tile-title;
  }

  rh-tile-card-body {
    .tile-content {
      overflow-y: hidden;
    }
  }
}
