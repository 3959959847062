/* Typography */
@fontPrimary: "Source Sans Pro", sans-serif;

/* Colors - General */
@lightGreyColor: #e5e5e5;
@whiteColor: #fff;
@primary: #64a5ff;
@lightPrimary: #9dc4fc;
@secondary: #133f7f;
@background: #f1f1f1;

/* Colors - Specific usages */
@linkColor: @secondary;
@linkActionColor: #333;
@checkBoxColor: rgb(0, 50, 99);
@btnColorMedium: #ccc;
@dangerBackgroundColor: #d9534f;
@dangerBorderColor: #d33a35;
@bgLightBootstrapColor: #f5f5f5;

/* Icons */
@commentIcon: "\f075";

/* Navbar */
@navBarHeight: 64px;

@fieldBorderRadius: 0;

/* values from twitter bootstrap sizes*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
@extraSmallMobile: 320px;
@smallMobile: 375px;
@smallDeviceTablet: 768px;
@mediumDeviceDesktop: 992px;
@largeDeviceDesktop: 1200px;

.extraSmallMobile(@rules) {
  @media screen and (max-width: @extraSmallMobile) {
    @rules();
  }
}

.smallMobile(@rules) {
  @media screen and (max-width: @smallMobile) {
    @rules();
  }
}

.smallDeviceTablet(@rules) {
  @media screen and (max-width: @smallDeviceTablet) {
    @rules();
  }
}
