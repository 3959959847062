@import "../tiles";
@import "../../../GlobalStyles/globalVariables.less";
rh-carousel-tile {
  .carousel {
    min-height: @TileHeight; //set to avoid jumping of arrows during page load
  }
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
  }
  .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    border: 1px solid #a19f9f;
    border-radius: 10px;
  }
  .carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #a19f9f;
  }

  .control {
    font-size: 30px;
    width: 45px;
    height: 45px;
    line-height: normal;
    position: absolute;
    top: 50%;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
    background-color: white;
    border-radius: 50%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);

    &:hover {
      color: #ffffff;
      text-decoration: none;
      background-color: lightgray;
      opacity: 0.7;
    }
  }

  .control.left {
    margin-left: 25px;
  }

  .control.right {
    right: 0;
    margin-right: 25px;
  }
}
