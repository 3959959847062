rh-pagination {
  .rh-pagination {
    display: flex;
    float: right;
    height: 50px;
    align-items: center;
    justify-content: center;
  }

  .pagination > li {
    > a {
      border: solid 1px #d1d6dc;

      &:hover {
        background-color: #fff;
        color: #848484;
        border-color: #adadad;
      }
    }

    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
      background-color: #133f7f;
      color: #fff;
      border: solid 1px #133f7f;
    }

    &.disabled > a {
      color: #767676; //For wcag contrast
    }
  }
}
