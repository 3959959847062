@import "../../../GlobalStyles/globalVariables.less";

/* Horsey autocomplete renders itself in body with following outer class */
.sey-container {
  background-color: @whiteColor;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 0;
  .sey-categories {
    .sey-category {
      .header {
        border-bottom: 1px solid @bgLightBootstrapColor;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 18px;
        padding-bottom: 2px;
        font-weight: bold;
        text-align: right;
      }
      .sey-list {
        .sey-item {
          color: #333;
          padding: 9px 15px;
          border-color: transparent;
          border-width: 3px;
          border-style: hidden;
          &.sey-selected,
          &:hover {
            background-color: @bgLightBootstrapColor;
            border-left-style: solid;
            padding-left: 12px;
          }
          .info-icon {
            margin-right: 15px;
            opacity: 0.75;
          }
        }
      }
    }
  }
}
