

 /*
 The classes can be used to mimic bootstrap's .hidden-... classes but based on some html element width - not the windows width
 <div class="hidden-element-container">
    <div class=".hidden-element-sm">
      This will be hidden when the container div has Sm size
    </div>   
    <div class=".hidden-element-xs">
      This will be hidden when the container div has Xs size
    </div>   
 </div>
 
 This requires the ElementQueries of css-element-queries library to be hooked up by ElementQueries.init(); or ElementQueries.listen(); 
 https://github.com/marcj/css-element-queries

 */ 

 

.hidden-element-container[max-width~="1199px"] .hidden-element-md {
  display: none !important;
}
  
.hidden-element-container[max-width~="991px"] .hidden-element-sm {
  display: none !important;
}
  
.hidden-element-container[max-width~="767px"] .hidden-element-xs {
  display: none !important;
}
 