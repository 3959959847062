rh-mobile-menu {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;

  .overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: black;
    opacity: 0.5;
    z-index: 2;
  }

  .mobile-menu-content {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 90vw;
    height: 80vh;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1001;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 10px;
      padding-bottom: 5px;
      font-size: 1.5em;
      text-align: center;
      border-bottom: 1px #e5e5e5 solid;
    }
  }

  .mobile-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    margin: 25px;
    border: 1px solid #333;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    font-size: 21px;
    background: #fff;
    color: rgb(0, 50, 99);
    z-index: 1003;

    &:focus {
      outline-style: none;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
