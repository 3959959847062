@import "../../../GlobalStyles/globalVariables.less";

rh-mobile-tabs {
  .mobile-tabs {
    height: 50px;
    width: 100vw;
    background-color: white;
    margin-left: -15px;
    margin-top: 6px;
    position: relative;
    z-index: 1;

    .tab-header {
      font-size: 1.2em;
      font-weight: 500;
    }

    > .tab {
      border: 1px solid rgb(0, 50, 99);
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        border-bottom: none;
      }

      &:focus {
        outline: none;
      }
    }

    > .filtering {
      float: left;
      border-left: none;
    }

    > .sorting {
      border-left: none;
      border-right: none;
      float: right;
    }
  }

  .options {
    width: 100vw;
    background-color: white;
    margin-top: 50px;
    margin-bottom: -1px;
    border-bottom: 1px solid rgb(0, 50, 99);
    position: absolute;

    &.taxonomy-option {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .sort-option {
      border-bottom: 1px solid rgb(0, 50, 99);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.selected {
        background-color: #ccc;
      }

      &:focus {
        outline: none;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .reverse-order-icon {
      margin-left: 5px;
    }
  }
}
