@import "../tiles";

rh-multi-link-tile {
  .tile-title {
    .default-tile-title;
  }

  rh-tile-card .card rh-tile-card-body .fade-out-line {
    bottom: 0px;
  }

  rh-tile-card-body {
    .tile-content {
      overflow: auto;
    }
  }
}
