@import "../../../GlobalStyles/globalVariables.less";

rh-search-box {
  display: flex;

  .input-group-addon.addon-width {
    min-width: 44px;
    max-width: 44px;
  }

  .search-box {
    border: 1px solid #ccc;
    position: relative;
    height: 50px;
    display: inline-block;
    padding: 4px 4px 0 10px;
    background-color: white;
    padding-right: 42px;
    margin-right: 5px;
    width: 100%;
    .search-input-label {
      display: none;
    }
    .input-wrapper {
      padding-left: 16px;
      padding-right: 22px;

      input {
        margin-left: 14px;
        height: 40px;
        border: 0;
        padding-left: 4px;
        width: 100%;

        &:focus {
          outline: none;
        }

        .smallMobile({margin: 0; padding-left: 10px;});
      }
    }
    .search-button {
      height: 42px;
      width: 42px;
      position: absolute;
      top: 3px;
      right: 3px;
      background-color: lighten(@btnColorMedium, 10%);

      &:hover,
      &:active,
      &:focus {
        background-color: @btnColorMedium;
      }
    }
  }
  .left-icon {
    i {
      top: 16px;
      left: 16px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 320px) {
  rh-search-assistent .search-box {
    width: 100%;
    margin-bottom: 4px;
  }
}
