@import "../../../GlobalStyles/globalVariables.less";

rh-taxonomy, rh-local-taxonomy {
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .taxonomy-feature {
    /*display: block;*/
    ul.nav {
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;

      ul.nav {
        margin-left: 0;
        border-left: 1px #ccc solid;
        margin-left: 5px;
      }

      li {
        @actionButtonsPlaceholderWidth: 85px;
        @minimumTaxonLabelWidth: 50px;
        white-space: nowrap;
        margin-top: 3px;

        * {
          vertical-align: top;
        }

        span.item-text {
          display: inline-block;
          padding: 0px 0px 0px 20px;
          max-width: ~"calc(100% - @{actionButtonsPlaceholderWidth})";
          min-width: @minimumTaxonLabelWidth;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          padding-right: 0px;
        }

        a {
          display: inline-block;

          &.item-text-link {
            color: @linkActionColor;
            cursor: pointer;
            text-decoration: none;
            padding-left: 20px;
            padding-right: 0px;
            padding-top: 2px;
            padding-bottom: 2px;
            max-width: ~"calc(100% - @{actionButtonsPlaceholderWidth})";
            min-width: @minimumTaxonLabelWidth; //the next section is to fix overflow ellipsis with before pseudo element!
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;

            &:before {
              display: inline-block;
              position: absolute; //important to not kill ellipsis on a tag
              left: 0px;
              text-decoration: none;
              font-size: 20px;
              font-family: arial;
              padding-right: 4px;
            }

            &:after {
              display: none;
            }

            &.item-text-link-collapsed:before {
              content: "+";
            }

            &.item-text-link-expanded:before {
              content: "−";
            }

            &.item-text-link-noresults {
              color: #bcbcbc;
              padding-right: 0px;
            }
          }
        }

        rh-taxon-match {
          display: inline-block;
          margin-top: 3px;
        }

        md-checkbox {
          display: inline-block;
          margin-top: 2px;
          margin-bottom: 0px;
        }
      }
    }

    .item-text-link-noresults {
      color: #bcbcbc;
      padding-right: 0px;
    }

    a.taxonomy-search-link {
      margin: 5px 0px 0px -7px;
      padding: 0;
      display: inline-block;
      color: @linkColor;
    }
  }

  @media (max-width: @smallMobile) {
    .taxonomy-feature {
      ul.nav {
        ul.nav {
          border-left: none;
        }

        li {
          margin: 0;

          .taxonomy-row {
            border: solid rgb(0, 50, 99) 1px;
            border-right: none;
            padding-left: 40px;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-top: -1px;

            &.header {
              background-color: #ccc;
            }
          }

          .sub-tree {
            background-color: white;

            &:last-child {
              border-bottom: none;
            }
          }

          a {
            &.item-text-link {
              &:hover,
              &:focus {
                outline: none;
                background-color: #ccc;
              }

              &.item-text-link-collapsed:before {
                margin-left: 10px;
              }

              &.item-text-link-expanded:before {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
