@import "../../../GlobalStyles/globalVariables.less";

rh-result-sorter {
  .sorting {
    display: flex;
    flex-wrap: nowrap;

    .reverse-order {
      border-left: none;
      text-overflow: clip;
    }
  }
}
