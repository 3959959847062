@import "../tiles";

rh-product-tile {
  rh-tile-card-header {
    height: @LargePictureHeight;

    .picture {
      .spanning-picture;
    }
  }

  rh-tile-card-body {
    .tile-content {
      overflow: hidden;
    }
  }

  .tile-title {
    .default-tile-title;
  }
}

@media (min-width: 768px) {
  .col-sm-12 rh-product-tile .tile-picture {
    float: left;
    width: 31.5%;
    height: @TileHeight;
  }

  .col-sm-12 rh-product-tile .tile-content {
    margin-left: 31.5%;
  }

  .col-sm-12 rh-product-tile .tile-content .tile-description {
    height: 290px;
  }

  .col-sm-12 rh-product-tile .tile-content a {
    float: right;
    width: 40%;
  }

  .col-sm-12 rh-product-tile .tile-title {
    max-width: 28%;
  }
}
