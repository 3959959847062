.search-results-caption {
  margin-top: 9px;
}

.search-chips md-chips {
  display: inline-block;
  margin-right: 10px;
}

.search-chips label.control-label {
  margin-top: -35px;
}

.search-term-menu-item {
  height: 20px;
  font-size: 18px;
}

.taxon-search-form .md-chip-remove {
  text-align: center;
  width: 32px;
  height: 32px;
  min-width: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  position: relative;
}

.taxon-search-form .md-chip-remove md-icon {
  width: 18px;
  height: 18px;
}

.search-taxonomy {
  display: inline-block;
}

.search-taxonomy a,
.search-taxonomy a:hover,
a.taxonomy-link {
  text-decoration: underline;
}

.historical-input {
  margin-left: 10px;
}

.terms-autocomplete-menu li {
  font-size: 18px;
}

md-virtual-repeat-container {
  width: 350px;
}

/* Styling left side panel search groups */
md-checkbox.search-group-cb {
  display: block;
}

md-checkbox.search-group-cb .md-icon {
  transform: scale(0.7);
}

md-checkbox.md-checked.search-group-cb .md-icon {
  background-color: #337ab7;
  /*TODO change global styling of md, or remove md*/
}


body .search-page-results .search-page-results-table-switch a.btn {
  text-decoration: none;
  color: #4e4e4e;
}